/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';

import Nav3 from './Nav3';
import Banner3 from './Banner3';
import Feature4 from './Feature4';
import Feature5 from './Feature5';
import Content11 from './Content11';
import Chat from './Chat';
import Teams0 from './Teams0';
import Footer1 from './Footer1';

import {
  Nav30DataSource,
  Banner30DataSource,
  Feature40DataSource,
  Content110DataSource,
  Feature41DataSource,
  Feature00DataSource,
  Teams00DataSource,
  Footer10DataSource,
  Feature50DataSource
} from './data.source';
import './less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
    };
    
  }

  componentWillUnmount() {
    // 清除滚动条缓存，滚动到页面顶部
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    
    
  
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
  }

  render() {
    const children = [
      // <Nav3
      //   id="Nav3_0"
      //   key="Nav3_0"
      //   dataSource={Nav30DataSource}
      //   isMobile={this.state.isMobile}
      // />,
      <Banner3
        id="Banner3_0"
        key="Banner3_0"
        dataSource={Banner30DataSource}
        isMobile={this.state.isMobile}
      />,
      <Chat 
      id="Chat_0"
      key="Chat_0"
      dataSource={Banner30DataSource}
      isMobile={this.state.isMobile}
      />,
      // <Feature4
      //   id="Feature4_0"
      //   key="Feature4_0"
      //   dataSource={Feature40DataSource}
      //   isMobile={this.state.isMobile}
      // />,
    //   <Feature0
    //   id="Feature0_0"
    //   key="Feature0_0"
    //   dataSource={Feature00DataSource}
    //   isMobile={this.state.isMobile}
    // />,
    <Feature5
      id="Feature5_0"
      key="Feature5_0"
      dataSource={Feature50DataSource}
      isMobile={this.state.isMobile}
    />,
      // <Content11
      //   id="Content11_0"
      //   key="Content11_0"
      //   dataSource={Content110DataSource}
      //   isMobile={this.state.isMobile}
      // />,
      <Teams0
        id="Teams0_0"
        key="Teams0_0"
        dataSource={Teams00DataSource}
        isMobile={this.state.isMobile}
      />,
      // <Footer1
      //   id="Footer1_0"
      //   key="Footer1_0"
      //   dataSource={Footer10DataSource}
      //   isMobile={this.state.isMobile}
      // />,
    ];
    return (
      <div
        className="templates-wrapper"
        style={{minHeight:'100vh'}}
        // ref={(d) => {
        //   this.dom = d;
        // }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {/* {this.state.show && children}
         */}
         {children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    );
  }
}
