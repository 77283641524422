/* eslint-disable react-hooks/exhaustive-deps */
import { DualAxes } from '@ant-design/plots';
import './index.less'
import { useEffect } from 'react';

const DualAxesChart = ({ dataSource, isLoading, isMobile }) => {

    useEffect(() => {
        console.log(isMobile);
    },[])
    
    return <DualAxes autoFit={true}
    xField={(d) => new Date(d.tradedate)}
    paddingLeft={isMobile?5:20}
    paddingRight={isMobile?5:20}
    scale={{
        color: { range: ['#826AC8', '#1860ED', '#F36B08', '#96B05A'] }
    }}
    axis = {isMobile?{
        x:{
            labelFontSize:10, 
        },
        y:{
            labelFontSize:10, 
        }
    }:{}}
    legend={{
        color: {
            // layout:"flex",
            itemLabelFontSize:isMobile?10:16,
            layout: isMobile?{
                justifyContent: 'flex-start',
                alignItems: 'center',
                // flexDirection: 'column',
            }:{
                justifyContent: 'flex-end',
                alignItems: 'center',
            },
        },
        size:{
            itemLabelFontSize:isMobile?10:16,
        }
    }}
  
    tooltip={{ channel: 'y',  valueFormatter: (v) => parseFloat(v).toFixed(2) }}
    children={[
        {
            data: dataSource,
            type: 'line',
            yField: 'value',
            colorField: 'type',
            shapeField: 'smooth',
            style: { lineWidth: 2 },
        },

    ]} />

};

export default DualAxesChart;