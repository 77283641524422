import React from 'react'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import BannerAnim, { Element } from 'rc-banner-anim'
import TweenOne from 'rc-tween-one'
import QueueAnim from 'rc-queue-anim'
import { getChildrenToRender } from './utils'
import { Button, message, Spin } from 'antd'
import Subscribe from '.././Component/Subscribe'
import { get, post } from '../http'
import 'rc-banner-anim/assets/index.css'

class Teams extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isFocused: false,
      email: null,
      visible: false,
      submitEmail: false,
    };

  }
  getChildrenToRender = (children) => {
    return children.map((item, i) => {
      const { titleWrapper, ...elementPros } = item
      return (
        <Element
          {...elementPros}
          key={i.toString()}
          prefixCls={elementPros.className}
        >
          <QueueAnim
            type={['bottom', 'top']}
            delay={200}
            key="text"
            {...titleWrapper}
          >
            {titleWrapper.children.map(getChildrenToRender)}
          </QueueAnim>
        </Element>
      )
    })
  }

  isValidEmail(email) {
    // 定义邮箱的正则表达式
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    // 使用test方法检测邮箱格式是否符合正则表达式
    return emailRegex.test(email)
  }
  async subscribeEmail() {
    try {
      if (!this.isValidEmail(this.state.email)) {
        message.warning('Email is not a valid email !')
        return
      }
      let url1 = `https://dev2022.shixiangcap.com/sxfiles/etna/login/tmp/user/insert`
      this.setState({
        visible: true,
      });
      const response = await post(url1, {
        email: this.state.email
      })
      this.setState({
        visible: false,
      });

      if (response.code !== 200) throw new Error(response.msg)
      message.success('Subscribe Success !')
      console.log(response.data)
      this.setState({
        submitEmail: true,
      })
      return response
    } catch (e) {
      this.setState({
        visible: false,
      });

      return {
        success: false,
        message: e.message
      }
    }
  }
  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  render() {
    const { ...tagProps } = this.props
    const { dataSource, isMobile } = tagProps
    delete tagProps.dataSource
    delete tagProps.isMobile
    return (
      <div {...tagProps} {...dataSource.wrapper}>
        <div className='connect-block'>
          <div style={{ position: 'relative', zIndex: 999 }} className='connect-title'>Let's Connect</div>
          {/* <div className='connect-desc'>Stay updated on Etna Research for cutting-edge insignts</div> */}
          <div style={{ marginTop: '-60px', position: 'relative', zIndex: 888 }}>
            <Subscribe isWhite={false} />
          </div>
        </div>
        <div className='connect-footer'>
          <div>
            <div className='connect-footer-logo'></div>
            <div className='connect-footer-desc'>
              fueling the future, navigator for innovators
            </div>
          </div>
          <div style={{marginRight:isMobile?0:"10%"}}>
            <div className='connect-footer-desc'>
              Privacy Policy
            </div>
            <div className='connect-footer-desc'>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                Contact Us
                <a href="https://twitter.com/EtnaResearch" target="_blank" rel="noreferrer" ><div className='connect-footer-x'></div></a>
                <a href="https://www.linkedin.com/company/etna-fund" target="_blank" rel="noreferrer" ><div className='connect-footer-in'></div></a>



              </div>
            </div>
            <div className='connect-footer-desc'>
              Palo Alto · HongKong
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Teams
